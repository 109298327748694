import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Flower from "../../images/pearlot-name-frame.svg"
import Flower2 from "../../images/pearlot-name-frame-02.svg"
import { Helmet } from "react-helmet"
const pearlot = () => {
  return (
    <div className="bg-[#F3E9E1] mx-auto px-4 sm:px-12 md:px-16 lg:px-56 pb-20">
      <Helmet title="Ali Hammad | Pearlot">
        <title>Pearlot</title>
        <meta
          name="description"
          content="Pearlot is a luxury jewelry pearl web design project. It's been built step by step from Ui/UX design to development and deployment"
        />
      </Helmet>
      <div className=" w-full">
        <div className="mb-5 w-[14.5rem] md:w-[17.7rem] mx-auto items-center text-center  flex flex-row  pt-10">
          <img src={Flower} className="w-12" alt="title" />
          <h2 className="px-4 font-medium text-[#B45C47] text-2xl md:text-4xl lg:text-4xl text-center tracking-wide">
            PEARLOT
          </h2>
          <img src={Flower2} alt="title" className="w-12 rotate-x-[180deg]" />
        </div>

        <div className="w-full overflow-hidden">
          <StaticImage
            className="object-cover scale-125 w-full h-full "
            src="../../images/projects/webp/pearlot-big.webp"
            alt="pearlot"
          />
        </div>
        <div className="mt-14 w-full h-px bg-[#B45C47]"></div>
        <div className="py-12 lg:py-28 flex flex-col lg:flex-row gap-5 lg:gap-20 xl:gap-44 justify-between w-full">
          <div className="w-full">
            <h4 className="text-[#B45C47] font-semibold text-center lg:text-left text-2xl opacity-90">
              About
            </h4>
            <p className="mb-10 lg:mb-16 text-center lg:text-justify text-black text-sm lg:text-base lg:w-full  leading-6 opacity-50 mt-6 font-light">
              Pearlote is a luxury pearl jewelry online store. It sells
              high-quality products in different categories like rings,
              earrings, necklaces, bracelets, and more.
              <br />
              <br />
              Most of their customers are of the female gender, and they live in
              Europe. Most of their pearls are imported from Japan.
            </p>
          </div>
          <div className="w-full">
            <h4 className="text-center lg:text-left text-[#B45C47] text-2xl font-semibold opacity-90">
              My Role
            </h4>
            <p className="mb-6 lg:mb-16 text-center lg:text-left lg:text-base text-black text-sm lg:w-full  leading-6 opacity-50 mt-6 font-light">
              My duty is to convert their vision, purpose, and emotions into an
              online store website. The process starts with designing the visual
              items, choosing color schemes, and building a unique visual
              identity for their service, which is a special step in the product
              design process. Then I collaborate with the engineers to convert
              the prototype into a real web app.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between w-full gap-8 bg-zinc-50 p-8">
        <StaticImage
          src="../../images/projects/webp/long-screen.webp"
          alt="pearlot"
        />
        <div className="flex flex-col gap-8 lg:gap-12 lg:translate-y-36">
          <StaticImage
            className="w-full"
            src="../../images/projects/webp/pearlot-screens-4.webp"
            alt="pearlot"
          />
          <StaticImage
            className="w-full"
            src="../../images/projects/webp/pearlot-screens-5.webp"
            alt="pearlot"
          />
        </div>
      </div>
      <StaticImage
        className="w-full mt-16"
        src="../../images/projects/webp/pearlot-screens.webp"
        alt="pearlot"
      />
      <p className="text-sm opacity-70 mt-8 text-center">
        Pearlot is inprocess, it will be launched soon
      </p>
      <p className="text-xs opacity-50 mt-1 text-center">
        The assets used in this project are from: Unsplash, and Pinterest
      </p>
    </div>
  )
}

export default pearlot
